.bottom-container {
  .bottom-container-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}
.custom-tooltip .tooltip-inner {
  text-align: justify !important;
}
