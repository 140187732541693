.member-filter {
  .query-builder.qb-lite {
    .group--header {
      .group--drag-handler,
      .group--actions {
        opacity: 1 !important;
      }
    }

    .rule_group {
      .group--drag-handler,
      .group--actions {
        opacity: 1 !important;
      }
    }
  }

  .btn-group {
    button {
      .fa-check {
        display: none;
      }

      &[value="true"]:after {
        content: "Enable";
        display: inline-block;
      }

      .fa-xmark {
        display: none;
      }

      &[value="false"]:after {
        content: "Disable";
        display: inline-block;
      }
    }
  }
}
